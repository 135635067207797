import { Pagination, Table, TBody } from "../../../components";
import { PaginationProps } from "../../../components/common/Pagination";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexRateInfo,
} from "../../../types";
import { RateInfoProps } from "../types";
import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import TierUpdateButton from "./TierUpdateButton";
import useTierList from "./useTierList";

const Tierlist = ({ rateInfo }: Pick<RateInfoProps, "rateInfo">) => {
  const { isLoading, fetchList, total, list } = useTierList();

  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <PermissionsProvider
        permission={AfriexPermissions.RATES_PAGE}
        action={AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER}
      >
        <div className="w-full flex justify-end mb-3">
          <TierUpdateButton rateInfo={rateInfo as AfriexRateInfo} />
        </div>
      </PermissionsProvider>
      <main className="pt-5 py-10 overflow-x-auto">
        <Table>
          <ListHeader />
          <TBody>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              list?.map((item, index) => (
                <ListItem
                  index={index}
                  item={item}
                  key={item.id}
                  rateInfo={rateInfo as AfriexRateInfo}
                />
              ))
            )}
          </TBody>
        </Table>
      </main>

      <footer>
        {list && list.length ? (
          <Pagination
            onPageChange={fetchList as PaginationProps["onPageChange"]}
            total={total}
            isSearch={false}
          />
        ) : null}
      </footer>
    </div>
  );
};

export default Tierlist;
