import { ReactElement } from "react";
import {
  FilterBar,
  Header,
  Pagination,
  Table,
  TBody,
} from "../../../components";
import {
  FilterAttribute,
  FilterAttributeType,
} from "../../../components/FilterBar/types";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import {
  AfriexActions,
  AfriexOTCVolume,
  AfriexPermissions,
  SupportedCurrencies,
} from "../../../types";
import { mapCountryListToOptions } from "../../../utils/mapCountryListToOptions";
import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import OTCUpdateButton from "./OTCUpdateButton";

import useOTCVolumeList from "./useOTCVolumeList";
import { PaginationProps } from "../../../components/common/Pagination";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";

const OTCVolumes = (): ReactElement => {
  const {
    countryList,
    isLoading,
    isSearch,
    fetchList,
    handleApprove,
    handleFilters,
    total,
    list,
  } = useOTCVolumeList();
  const countryOptions = mapCountryListToOptions(countryList ?? []);
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  /**
   * These are the filter attributes that will be passed to the filter bar
   */
  const attributes: FilterAttribute[] = [
    {
      title: "From Date",
      label: "fromDate",
      type: FilterAttributeType.Date,
    },
    {
      title: "To Date",
      label: "toDate",
      type: FilterAttributeType.Date,
    },
    {
      title: "Currency",
      label: "currency",
      type: FilterAttributeType.Select,
      options: SupportedCurrencies,
    },
  ];

  return (
    <div className=" my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title={"OTC Volumes" + totalFigure} />

      <PermissionsProvider
        permission={AfriexPermissions.RATES_PAGE}
        action={AfriexActions.CLICK_RATES_OTC_UPLOAD}
      >
        <div className="w-full flex justify-end mb-3">
          <OTCUpdateButton />
        </div>
      </PermissionsProvider>

      <FilterBar
        key={Object.keys(countryOptions)?.join("")}
        attributes={attributes}
        onSubmit={handleFilters}
      />
      <main className="pt-5 py-10 overflow-x-auto">
        <Table>
          <ListHeader />
          <TBody>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              list.map((item: AfriexOTCVolume, index: number) => (
                <ListItem
                  handleApprove={handleApprove}
                  index={index}
                  item={item}
                  key={item.id}
                />
              ))
            )}
          </TBody>
        </Table>
      </main>
      <footer>
        {list && list.length ? (
          <Pagination
            onPageChange={fetchList as PaginationProps["onPageChange"]}
            total={total}
            isSearch={isSearch}
          />
        ) : null}
      </footer>
    </div>
  );
};

export default OTCVolumes;
