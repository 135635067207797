import { useCallback } from "react";
import { AfriexUserQueryParams } from "../types/User";
import { identity, pickBy } from "lodash";
import moment from "moment";
import { AfriexTransactionQueryParams } from "../screens/Transaction/List/types";
import { toast } from "react-toastify";
import { AfriexQueryParams } from "../types";

export type queryParamsType =
  | AfriexUserQueryParams
  | AfriexTransactionQueryParams
  | AfriexQueryParams;

const useFilters = <T extends queryParamsType>(
  setFilters: React.Dispatch<React.SetStateAction<T>>
) => {
  const applyFilters = useCallback(
    (filters: T) => {
      const cleanedFilters = pickBy(filters, identity) as Partial<T>;

      // Check if both fromDate and toDate are present and valid
      const hasValidDateRange =
        cleanedFilters.fromDate &&
        cleanedFilters.toDate &&
        moment(cleanedFilters.toDate).isAfter(moment(cleanedFilters.fromDate));

      if (hasValidDateRange) {
        cleanedFilters.fromDate = moment(cleanedFilters.fromDate)
          .startOf("day")
          .toISOString();

        cleanedFilters.toDate = moment(cleanedFilters.toDate)
          .startOf("day")
          .toISOString();
      } else if (cleanedFilters.fromDate || cleanedFilters.toDate) {
        toast.error("From Date can not be higher than To Date");
        delete cleanedFilters.fromDate;
        delete cleanedFilters.toDate;
      }
      setFilters(cleanedFilters as T);
    },
    [setFilters]
  );

  return { applyFilters };
};

export default useFilters;
