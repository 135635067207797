import { XIcon } from "@heroicons/react/outline";
import { getAdminRoleOptions } from "../../../../constants/formatters";
import { AdminFiltersProps } from "../../../../types/AfriexAdmin";
import { CustomSelect } from "../../../../components";

const AdminFilters = ({
  currentFilters,
  clearFilters,
  selectCurrentFilters,
}: AdminFiltersProps) => {
  return (
    <>
      <div className="mt-5 w-full">
        <div className="flex items-center justify-between">
          {/* Left Side if needed */}
          <div className="flex items-center space-x-3 w-3/5" />

          <div className="flex items-center space-x-3">
            <button
              onClick={clearFilters}
              title="Clear search"
              className="h-8 w-8 hidden md:flex rounded-md border border-gray-300 items-center justify-center hover:border-cyan-600 hover:text-cyan-600 group"
            >
              <XIcon className="h-5 w-5 text-gray-400 group-hover:text-cyan-600" />
            </button>
            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                selectCurrentFilters("roles", e.target.value)
              }
              value={currentFilters.roles}
              name="roles"
              options={getAdminRoleOptions()}
              placeholder="Filter by Role"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminFilters;
