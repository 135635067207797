import {
  AfriexCountry,
  AfriexKYCInfo,
  AfriexKYCProcessor,
  AfriexPaginatedResponse,
  AfriexPerson,
  AfriexVerificationStatus,
} from "../types";
import { AfriexUserQueryParams } from "../types/User";
import axios from "../api/axios";
import { showSuccessMessage } from "../utils/showSuccessMessage";
import { showErrorMessage } from "../utils/showErrorMessage";
import { AfriexComplianceDocumentTypes } from "../types/AfriexComplianceDocumentTypes";

type KYCParams = {
  status?: AfriexVerificationStatus;
};
export const kycService = {
  getCountryList: async ({
    limit = 100,
    page = 0,
  }): Promise<AfriexPaginatedResponse<AfriexCountry[]>> => {
    const params = {
      page,
      limit,
      columns: ["name", "iso2", "flag", "status", "currency"],
    } as any;

    const url = `/v2/admin/countries?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getKYCList: async (
    filters: AfriexUserQueryParams & KYCParams
  ): Promise<AfriexPaginatedResponse<AfriexPerson[]>> => {
    const paginationParams = {
      page: 0,
      limit: 10,
    } as any;
    const params = { ...paginationParams, ...filters };
    const url = `/v2/admin/kyc?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response?.data;
  },
  getKYCDetails: async (userId: string) => {
    const response = await axios.get(`/v2/admin/kyc/${userId}`);
    return response.data;
  },
  uploadKYC: async (
    userId: string,
    payload: Partial<AfriexKYCInfo>
  ): Promise<any> => {
    const url = `/v2/admin/kyc/${userId}`;
    const response = await axios.patch(url, payload);
    return response?.data;
  },
  submitKYC: async (
    userId: string,
    processor: AfriexKYCProcessor = AfriexKYCProcessor.Veriff
  ): Promise<any> => {
    const url = `/v2/admin/kyc/verify`;
    const response = await axios.post(url, {
      userId,
      processor,
    });
    return response?.data;
  },
  updateKycStatus: async (
    userId: string,
    status: AfriexVerificationStatus,
    data?: { requestedAdditionalDocuments: AfriexComplianceDocumentTypes[] }
  ) => {
    const response = await axios.patch(`/v2/admin/kyc/status/${userId}`, {
      status,
      ...data,
    });
    return response.data;
  },
  deleteVeriffSesion: async (userId: string): Promise<any> => {
    const url = `/v2/admin/kyc/session/${userId}`;
    const response = await axios.delete(url, {});
    return response?.data;
  },
  verifyUser: async (
    userId: string,
    status: AfriexVerificationStatus
  ): Promise<any> => {
    const url = `/v2/admin/kyc/status/${userId}`;
    const response = await axios.patch(url, { status });
    return response?.data;
  },
  getVirtualAccount: async (userId: string) => {
    try {
      const url = `/v2/admin/users/virtual-account/${userId}`;
      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      showErrorMessage("Error getting virtual account information");
      console.error(e);
    }
  },
};
