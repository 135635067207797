import { useCallback, useState, useEffect } from "react";
import {
  AfriexAdmin,
  AfriexAdminListProps,
  AfriexAdminQueryParams,
} from "../../../../types";
import useFilters from "../../../../hooks/useFilters";
import { adminService } from "../../../../services/adminService";
import { userService } from "../../../../services/userService";
const useAdminsList = (): AfriexAdminListProps => {
  const [adminsList, setAdminsList] = useState<AfriexAdmin[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch] = useState<boolean>(false);
  const [adminsTotal, setAdminsTotal] = useState<number>(0);
  const [showPagination] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [isPageReset, setIsPageReset] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [currentFilters, setCurrentFilters] = useState<AfriexAdminQueryParams>(
    {}
  );
  useFilters(setCurrentFilters);

  const fetchAllAdmins = useCallback(
    async (params?: AfriexAdminQueryParams) => {
      try {
        setError(null);
        setIsLoading(true);
        const admins = await adminService.getAdmins({
          ...params,
          ...currentFilters,
        });

        const userIds = admins?.data?.map((admin) => admin.userId);

        const userProfiles = await Promise.all(
          userIds.map((userId) => userService.getUser(userId))
        );

        const mergedAdmins = admins.data.map((admin) => {
          const userProfile = userProfiles.find(
            (profile) => profile.id === admin.userId
          );
          return {
            ...admin,
            fullName: userProfile?.name?.fullName || "N/A",
          };
        });

        setAdminsList(mergedAdmins);
        setAdminsTotal(admins.total ?? 0);
      } catch (e) {
        setError(e as any);
      } finally {
        setIsLoading(false);
      }
    },
    [currentFilters]
  );

  useEffect(() => {
    fetchAllAdmins({});
  }, [fetchAllAdmins]);

  // Handle change for instant data fetching
  const selectCurrentFilters = useCallback(
    (name: string, value: string | Date | undefined) => {
      setCurrentFilters((prev) => {
        const updatedFilters = {
          ...prev,
          [name]: value,
        };
        return updatedFilters;
      });
      setIsPageReset((prev) => !prev);
    },
    []
  );

  const cleanFilters = () => {
    setCurrentFilters({});
    setIsPageReset((prev) => !prev);
  };

  return {
    error,
    fetchAdminsList: fetchAllAdmins,
    adminsList,
    isLoading,
    isSearch,
    setPage,
    pageInfo: {
      page,
      total: adminsTotal,
    },
    showPagination,
    isPageReset,
    currentFilters,
    clearFilters: cleanFilters,
    selectCurrentFilters,
  };
};

export default useAdminsList;
