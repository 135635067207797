import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { ChangeEvent, useEffect, useState } from "react";
import PaginationInput from "./PaginationInput";
import Button from "./Button";
import { queryParamsType } from "../../hooks/useFilters";

export type PaginationProps = {
  total?: number;
  limit?: number;
  isSearch?: boolean;
  isPageReset?: boolean;
  onPageChange: (queryParams: queryParamsType) => Promise<void>;
};

export default function Pagination({
  total = 0,
  limit = 10,
  onPageChange,
  isSearch = false,
  isPageReset: resetIndicator,
}: PaginationProps): JSX.Element {
  const totalPages = Math.ceil(total / limit);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumberInput, setPageNumberInput] = useState<number>(1);

  const setPage = (page: number) => {
    setCurrentPage(page);
    setPageNumberInput(page);
    onPageChange({ limit, page: page - 1 });
  };

  useEffect(() => {
    const resetPage = () => setPage(1);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetIndicator]);

  // When the Previous Button is Clicked
  function onPreviousPage() {
    if (currentPage > 1) setPage(currentPage - 1);
  }

  // When the Next Button is clicked
  function onNextPage() {
    if (currentPage < totalPages) setPage(currentPage + 1);
  }

  // when the user wants to visit a particular page number via input
  function handleGoToPage() {
    if (
      pageNumberInput >= 1 &&
      pageNumberInput <= totalPages &&
      pageNumberInput !== currentPage
    ) {
      setPage(pageNumberInput);
    }
  }

  // When the User inputs a pageNumber
  function handlePageInputChange(event: ChangeEvent<HTMLInputElement>) {
    setPageNumberInput(Number(event.target.value));
  }

  const textClass = "text-sm font-medium text-gray-800";

  return (
    <footer
      className={`border border-t-0 py-3 shadow-sm rounded-md rounded-tr-none rounded-tl-none bg-white px-8`}
    >
      <div className="w-full flex justify-between ">
        <div className="flex space-x-4 items-center">
          <div className={textClass}>{isSearch ? "Result" : "Page"}</div>

          <PaginationInput
            value={pageNumberInput}
            onChange={handlePageInputChange}
            onSubmit={handleGoToPage}
            isDisabled={
              pageNumberInput < 1 ||
              pageNumberInput === currentPage ||
              pageNumberInput > totalPages
            }
          />
          <div className={textClass}>of {totalPages}</div>
        </div>

        {/* Next and Prev Button */}
        <div className="flex gap-3">
          <Button
            variant="outline"
            colorScheme="cyan"
            onClick={onPreviousPage}
            disabled={currentPage === 1}
            leftIcon={<ChevronLeftIcon className="w-5 h-5" />}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            colorScheme="cyan"
            onClick={onNextPage}
            disabled={currentPage === totalPages || total === 0}
            leftIcon={<ChevronRightIcon className="w-5 h-5" />}
          >
            Next
          </Button>
        </div>
      </div>
    </footer>
  );
}
