import { ExclamationIcon } from "@heroicons/react/outline";
import Button from "../common/Button";
import Modal from "../common/Modal";
import SpinningLoader from "../common/SpinningLoader";

type PushNotificationModalProps = {
  loading: boolean;
  sendPushNotification: () => void;
  onClose: () => void;
  title: string;
  message: string;
};

export function SendPushNotificationModal({
  loading,
  title,
  message,
  sendPushNotification,
  onClose,
}: PushNotificationModalProps) {
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        {loading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <h3 className="font-bold text-center text-gray-900">
              Are you sure you want to send this Push Notification?
            </h3>
            <div className="flex justify-center">
              <div className="flex items-center gap-2 text-xs font-medium mt-3 text-orange-600">
                <ExclamationIcon className="w-5 h-5" />
                Double check the amount before sending
              </div>
            </div>
            <div className="pt-5 text-sm">
              <span className="font-semibold text-cyan-600">Title: </span>
              {title}
            </div>
            <div className="pt-5 text-sm">
              <span className="font-semibold text-cyan-600">Message: </span>
              {message}
            </div>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline" colorScheme="cyan">
                Cancel
              </Button>
              <Button
                onClick={sendPushNotification}
                variant="solid"
                colorScheme="cyan"
              >
                Send Push Notification
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
