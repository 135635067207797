import { isEmpty } from "lodash";
import { Fragment } from "react";
import { Button, InfoBox } from "../../../components";
import AppRatesTable from "../App/RatesTable";
import ArbitrageTable from "./ArbitrageTable";
import RatesUploadForm from "./RatesUploadForm";
import useRatesUpdate from "./useRatesUpdate";
import { AfriexPermissions } from "../../../types";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { AfriexActions } from "../../../types";
import ConfirmationModal from "../../../components/CustomModal/ConfirmationModal";
const RatesUpload = () => {
  const {
    arbitrageResult,
    handleTemplateDownload,
    handleAppRatesUpload,
    appRates,
    tolerance,
    handleToleranceChange,
    isArbitrageChecked,
    isArbitrageDetected,
    handleRatesCancellation,
    handleRatesUpdate,
    isLoading,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    loadingText,
  } = useRatesUpdate();
  const isDisabled =
    !isArbitrageChecked || isArbitrageDetected || isEmpty(appRates);
  return (
    <div>
      <PermissionsProvider
        permission={AfriexPermissions.RATES_PAGE}
        action={AfriexActions.CLICK_RATES_UPLOAD}
      >
        <RatesUploadForm
          handleAppRatesUpload={handleAppRatesUpload}
          handleTemplateDownload={handleTemplateDownload}
        />
      </PermissionsProvider>
      <br />
      {loadingText && (
        <InfoBox>
          <svg
            className="animate-spin h-5 w-5 mr-3 ..."
            viewBox="0 0 24 24"
          ></svg>
          {loadingText}
        </InfoBox>
      )}
      {arbitrageResult && isArbitrageChecked && (
        <ArbitrageTable
          arbitrageResult={arbitrageResult}
          rates={appRates}
          tolerance={tolerance}
          onToleranceChange={handleToleranceChange}
        />
      )}
      {!isEmpty(appRates) && !isArbitrageDetected && (
        <section style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            onClick={handleRatesCancellation}
            className="check-abs-btn hover:bg-blue-600 text-black py-1 px-3 rounded mt-4"
            style={{ background: "#ECECEC" }}
          >
            Clear
          </button>
        </section>
      )}
      {!isEmpty(appRates) && (
        <Fragment>
          <h3>App Rates</h3>
          <AppRatesTable rates={appRates} />
        </Fragment>
      )}
      <PermissionsProvider
        permission={AfriexPermissions.RATES_PAGE}
        action={AfriexActions.CLICK_RATES_UPLOAD_PRODUCTION}
      >
        {!isEmpty(appRates) && (
          <section className="action-wrap flex justify-end w-full">
            <Button
              disabled={isDisabled}
              className="btn border-2 p-2 text-sm bg-blue-500 text-white disabled:opacity-70 disabled:text-slate-200"
              data-tip
              data-for="productionPush"
              onClick={() => setIsConfirmModalOpen(true)}
              variant="solid"
            >
              Push to production
            </Button>
          </section>
        )}
      </PermissionsProvider>

      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        label="Are you sure to save these rates?"
        cancelAction={() => setIsConfirmModalOpen(false)}
        cancelActionLabel="No"
        confirmAction={() => handleRatesUpdate(appRates)}
        confirmActionLabel="Yes"
        loadingLabel={loadingText}
        loading={isLoading}
        title="Confirm to submit"
      />
    </div>
  );
};

export default RatesUpload;
