import { Header, Pagination, TableSkeleton } from "../../../../components";
import { PaginationProps } from "../../../../components/common/Pagination";
import AdminFilters from "./AdminFilters";
import AdminTable from "./AdminTable";
import useAdminsList from "./useAdminsList";

const Admins = () => {
  const {
    adminsList,
    isLoading,
    isSearch,
    isPageReset,
    pageInfo,
    fetchAdminsList,
    selectCurrentFilters,
    currentFilters,
    clearFilters,
  } = useAdminsList();

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Admins" />

      <AdminFilters
        currentFilters={currentFilters}
        clearFilters={clearFilters}
        selectCurrentFilters={selectCurrentFilters}
      />

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <AdminTable items={adminsList} />}
      </section>

      {adminsList && adminsList.length ? (
        <Pagination
          onPageChange={fetchAdminsList as PaginationProps["onPageChange"]}
          total={pageInfo.total}
          isSearch={isSearch}
          isPageReset={isPageReset}
        />
      ) : null}
    </main>
  );
};

export default Admins;
