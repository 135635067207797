import {
  Modal,
  SpinningLoader,
  Button,
  CustomSelect,
} from "../../../components";
import { CustomTextArea } from "../../../components/common/Input";
import {
  AfriexBlockReasonsMap,
  AfriexUnblockReasonsMap,
} from "../../../constants/formatters";
import { AfriexPerson } from "../../../types";

type ModalProps = {
  user: AfriexPerson;
  isLoading: boolean;
  isSecurityFlagged: boolean;
  onSubmit: () => void;
  onClose: () => void;
  securityNote: string;
  handleSecurityNoteChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleBlockReason: (val: string) => void;
  handleUnblockReason: (val: string) => void;
  unblockReason: string;
  blockReason: string;
};

export function BlockUserModal({
  user,
  isLoading,
  isSecurityFlagged,
  onClose,
  onSubmit,
  securityNote,
  handleSecurityNoteChange,
  handleBlockReason,
  blockReason,
  unblockReason,
  handleUnblockReason,
}: ModalProps) {
  const securityLabel = isSecurityFlagged ? "Unblock" : "Block";
  const label = `${securityLabel} ${user.name.firstName}`;
  const hasReason = blockReason || unblockReason ? true : false;
  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <h3 className="text-lg ">Enter security note to {label}</h3>
            <div className="text-sm font-light mt-3">
              <CustomTextArea
                name="securityNote"
                required
                onChange={handleSecurityNoteChange}
                value={securityNote ?? ""}
                aria-rowspan={5}
                type="text"
                className="w-full isFull"
                placeholder={`${label} because ...`}
              />
            </div>

            <div className="flex w-full sm:w-2/4 sm:mt-5">
              <CustomSelect
                onChange={
                  isSecurityFlagged ? handleUnblockReason : handleBlockReason
                }
                value={isSecurityFlagged ? unblockReason : blockReason}
                options={
                  isSecurityFlagged
                    ? AfriexUnblockReasonsMap
                    : AfriexBlockReasonsMap
                }
                name="reason"
                placeholder={`Select ${isSecurityFlagged ? "Unblock" : "Block"} reason`}
                label="Select Reason"
              />
            </div>

            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline" colorScheme="cyan">
                cancel
              </Button>
              <Button
                disabled={isLoading || !securityNote || !hasReason}
                onClick={onSubmit}
                colorScheme={user.isSecurityFlagged ? "orange" : "red"}
              >
                {label}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
