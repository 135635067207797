import { ReactElement } from "react";
import { Input, SpinningLoader } from "..";

type FilePickerProps = {
  name: string;
  label: string;
  isLoading: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const FilePicker = (props: FilePickerProps): ReactElement => {
  const { isLoading, className, ...others } = props;
  return (
    <div className={className || "mt-5"}>
      {isLoading ? <SpinningLoader /> : <Input type="file" {...others} />}
    </div>
  );
};

export default FilePicker;
