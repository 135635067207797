import { useState } from "react";
import {
  Button,
  ChevronRightIcon,
  Link,
  MinusIcon,
  PlusIcon,
  Td,
  Tr,
} from "../../../components";
import { AfriexRateInfo, AfriexTier } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import Summary from "./Summary";

type ListItemProps = {
  item: AfriexTier;
  index?: number;
  rateInfo?: AfriexRateInfo;
};
const ListItem = ({ item, rateInfo }: ListItemProps) => {
  const className = "text-sm text-black pl-3";
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <>
      <Tr className="hover:bg-indigo-200 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <Td className="pl-5">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant="unStyled">
            <div
              className={`border border-gray-300 w-5 h-5 flex justify-center rounded-lg hover:bg-sky-50 ${
                isExpanded && "bg-sky-50"
              }`}
            >
              {isExpanded ? (
                <MinusIcon className="w-4 h-4 text-indigo-500" />
              ) : (
                <PlusIcon className="text-gray-300 w-4 h-4 hover:text-indigo-500" />
              )}
            </div>
          </Button>
        </Td>

        <Td>
          <div className={className}>{item.name}</div>
        </Td>

        <Td>
          <div className={className}>{item.discount}%</div>
        </Td>

        <Td>
          <div className={className}>
            {item.isActive ? "ACTIVE" : "INACTIVE"}
          </div>
        </Td>

        <Td>
          <div className={className}>
            {formatDate(item.createdAt?.toString() ?? "")}
          </div>
        </Td>
        <Td>
          <div className="w-5 h-5 flex justify-center  text-gray-400 hover:border rounded-lg hover:border-gray-300 hover:bg-sky-50 hover:text-indigo-500">
            <Link
              to={`/rates/tiers/${item.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-5 h-5 flex justify-center  text-gray-400 hover:border rounded-lg hover:border-gray-300 hover:bg-sky-50 hover:text-indigo-500">
                <ChevronRightIcon className=" w-5 h-5" />
              </div>
            </Link>
          </div>
        </Td>
      </Tr>
      {isExpanded && (
        <Tr className="pr-5">
          <Td className="p-0 m-0" colspan={9}>
            <div className="flex py-10 px-8 justify-center bg-sky-100 w-full">
              <Summary item={item} rateInfo={rateInfo} />
            </div>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default ListItem;
