import {
  AfriexActions,
  AfriexPermissions,
  ReferralFiltersProps,
} from "../../../types";
import { useState } from "react";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { Filter } from "../../../components/Filter";
import {
  Button,
  CustomSelect,
  DatePicker,
  SearchBar,
} from "../../../components";
import { getReferralStatusOptions } from "../../../constants/formatters";

const ReferralFilters = ({
  filterCount,
  filtersToApply,
  currentFilters,
  clearFilters,
  selectAppliedFilters,
  handleApplyFilters,
  selectCurrentFilters,
  handleAutoComplete,
}: ReferralFiltersProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const fromDate = filtersToApply.fromDate
    ? new Date(filtersToApply.fromDate)
    : undefined;

  const toDate = filtersToApply.toDate
    ? new Date(filtersToApply.toDate)
    : undefined;

  const handleShowFilterChange = (): void => {
    setShowFilters(!showFilters);
  };

  const handleApplyFilter = (): void => {
    handleApplyFilters();
    setShowFilters(false);
  };

  const handleClearFilters = (): void => {
    setShowFilters(false);
    clearFilters();
  };

  return (
    <PermissionsProvider
      permission={AfriexPermissions.REFERRAL_PAGE}
      action={AfriexActions.CLICK_REFERRAL_SEARCH_FILTER}
    >
      <>
        <div className="mt-5 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3 w-3/5">
              {/* Search Bar */}
              <SearchBar
                handleSearch={handleAutoComplete}
                handleAutoComplete={handleAutoComplete}
                clearFilters={clearFilters}
                inputClassName="w-4/6"
              />

              <div className="flex items-center space-x-3">
                {/* Filter Box */}
                <div className="flex items-center space-x-3">
                  <Filter
                    handleShowFilter={handleShowFilterChange}
                    label="Filters"
                    bodyClassName="left-1/2 -translate-x-1/2"
                  >
                    {/* Filter Body */}
                    {showFilters && (
                      <div className="p-4 space-y-4 max-w-4xl mx-auto w-600">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="flex items-center gap-2">
                            <DatePicker
                              label="From Date"
                              selectedDate={fromDate}
                              setSelectedDate={(date) =>
                                selectAppliedFilters?.("fromDate", date)
                              }
                              placeholderText="Select Date"
                            />

                            <DatePicker
                              label="To Date"
                              selectedDate={toDate}
                              setSelectedDate={(date) =>
                                selectAppliedFilters?.("toDate", date)
                              }
                              placeholderText="Select Date"
                            />
                          </div>
                        </div>

                        <div className="flex justify-end">
                          <Button
                            onClick={handleClearFilters}
                            colorScheme="cyan"
                            variant="ghost"
                          >
                            Clear
                          </Button>

                          <Button
                            onClick={handleApplyFilter}
                            colorScheme="cyan"
                            className="ml-3"
                          >
                            Apply Filters {filterCount && ` (${filterCount})`}
                          </Button>
                        </div>
                      </div>
                    )}
                  </Filter>
                </div>
              </div>
            </div>

            {/* Status Select Box */}
            <div className="flex items-center space-x-3">
              <CustomSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  selectCurrentFilters("status", e.target.value)
                }
                value={currentFilters.status}
                name="status"
                options={getReferralStatusOptions()}
                placeholder="Filter by Status"
              />
            </div>
          </div>
        </div>
      </>
    </PermissionsProvider>
  );
};

export default ReferralFilters;
