import React, { useEffect } from "react";
import { Button } from "..";
import ReactDOM from "react-dom";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";
import { doNothing } from "../../constants/formatters";

export interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  width?: string;
  height?: string;
  zIndex?: number; // Update this on nested modals to show them above parent modals
  closeOnOutsideClick?: boolean;
  titleSize?: string;
  hideX?: boolean;
}

const CustomModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isOpen,
  onClose,
  width = "w-1/3",
  height = "h-auto",
  children,
  zIndex = 10,
  title,
  closeOnOutsideClick = false,
  titleSize,
  hideX = false,
}) => {
  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Render nothing if not open
  if (!isOpen) return null;

  // Create a portal div if it doesn't exist
  const modalRoot = document.getElementById("modal-root") || createModalRoot();

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center z-50"
      style={{ zIndex }}
      aria-modal="true"
      role="dialog"
    >
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={closeOnOutsideClick ? onClose : doNothing}
      ></div>

      {/* Modal Content */}
      <div
        className={clsx(
          "bg-white rounded-lg shadow-lg relative flex flex-col",
          width,
          height,
          "overflow-hidden"
        )}
      >
        {/* Modal Header */}
        <div className="flex justify-center items-center p-4 relative">
          <h1
            className={`${titleSize || "text-lg"} font-semibold text-center `}
          >
            {title}
          </h1>

          {!hideX && (
            <Button
              colorScheme="gray"
              variant="ghost"
              onClick={onClose}
              aria-label="Close Modal"
              className="no-padding absolute right-5"
            >
              <XIcon className="w-6 h-6 text-gray-800" />
            </Button>
          )}
        </div>
        {/* Modal Body */}
        <div className="flex-1 overflow-y-auto px-4 pb-4">{children}</div>
      </div>
    </div>,
    modalRoot
  );
};

// Helper function to create modal root if it doesn't exist
function createModalRoot() {
  const modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modal-root");
  document.body.appendChild(modalRoot);
  return modalRoot;
}

export default CustomModal;
