import { Tabs } from "../../components";
import { TabItem } from "../../types";
import AppRatesList from "./App";
import RatesUpload from "./Upload";
import OTCVolumeList from "./OTCVolume";
import PromoTiersList from "./Promo";
import useRates from "../../hooks/useRates";

const Rates = () => {
  const { rateInfo, isLoading } = useRates();

  const ratesTabs: TabItem[] = [
    {
      id: "app-rates",
      title: "App Rates",
      content: <AppRatesList isLoading={isLoading} rateInfo={rateInfo} />,
    },
    {
      id: "upload",
      title: "Upload Rates",
      content: <RatesUpload />,
    },
    {
      id: "otc-volumes",
      title: "OTC Volumes",
      content: <OTCVolumeList />,
    },
    {
      id: "promo-rates",
      title: "Promo Tiers",
      content: <PromoTiersList rateInfo={rateInfo} />,
    },
  ];
  return <Tabs tabs={ratesTabs} queryKey={"tab"} />;
};
export default Rates;
// U+0394
