import { XIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { isEmpty } from "lodash";
import { useImperativeHandle, useState } from "react";
import {
  AfriexActions,
  AfriexPermissions,
  SupportedCurrencies,
} from "../../types";
import { FeePaymentMethod, TransactionFee } from "./type";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";

const editableInputClasses = "w-24 rounded-md border-2 border-gray-300";
const readOnlyInputClasses = "w-24 border-0";
export type FeeEntryRef = {
  getFee: () => TransactionFee | undefined;
};

/**
 * FeeEntry is a component that allows the user to edit or create a new fee override.
 * It is a controlled component, meaning that it requires a ref to be passed in order to
 * access the current fee value. The ref is passed in as an innerRef prop.
 */
const FeeEntry = ({
  layout = "col",
  fee,
  editStyle = "modal",
  onEdit,
  onDelete,
  type = "override",
  innerRef,
}: {
  layout?: "row" | "col";
  fee: TransactionFee;
  editStyle?: "modal" | "inline";
  onEdit?: (fee: TransactionFee) => void;
  onDelete?: (fee: TransactionFee) => void;
  type?: "override" | "default";
  innerRef?: React.Ref<FeeEntryRef>;
}) => {
  const [fixedValue, setFixedValue] = useState(
    fee?.baseValue?.toString() ?? ""
  );
  const [percentage, setPercentage] = useState(fee?.percentValue?.toString());
  const [maxFee, setMaxFee] = useState(fee?.maxFeeValue?.toString());
  const [currencyPair, setCurrencyPair] = useState<string | undefined>(
    fee?.currencyPair
  );
  const sourceCurrency = currencyPair?.split(":")[0];
  const destinationCurrency = currencyPair?.split(":")[1];
  const [selectedInstrument, setInstrument] = useState<FeePaymentMethod>(
    fee?.paymentMethod as any
  );
  const [limitType, setLimitType] = useState<"above" | "below" | undefined>(
    fee?.limitType
  );
  const [limit, setLimit] = useState<number | undefined>(fee?.limit);
  const inputClasses =
    editStyle === "inline" ? editableInputClasses : readOnlyInputClasses;

  const disabled = editStyle === "modal";

  const methods = Object.values(FeePaymentMethod);
  const currencyPairs = Object.values(SupportedCurrencies);

  useImperativeHandle(
    innerRef,
    () => ({
      getFee: () =>
        ({
          paymentMethod: selectedInstrument,
          baseValue: !isEmpty(fixedValue) ? Number(fixedValue) : undefined,
          percentValue: !isEmpty(percentage) ? Number(percentage) : undefined,
          maxFeeValue: !isEmpty(maxFee) ? Number(maxFee) : undefined,
          limitType: limitType,
          currencyPair: !isEmpty(currencyPair) ? currencyPair : undefined,
          limit: limit,
          type,
        }) as TransactionFee,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedInstrument,
      fixedValue,
      percentage,
      maxFee,
      limitType,
      currencyPair,
      limit,
    ]
  );

  return (
    <div className="override-entry mb-4">
      <div className={`flex flex-${layout === "row" ? "col" : "row"}`}>
        {type === "override" && (
          <div className="mr-1">
            Payment Method:
            <select
              name="instrument"
              className={`${inputClasses} w-full block`}
              disabled={disabled}
              onChange={(e) => {
                const selectedMethod =
                  Object.create(FeePaymentMethod)[e.target.value];
                setInstrument(selectedMethod);
              }}
              value={selectedInstrument}
            >
              <option value={""}>Any</option>
              {methods.map((method) => (
                <option key={method} value={method}>
                  {method}
                </option>
              ))}
            </select>
          </div>
        )}
        {type === "override" && (
          <div className="mr-1">
            Currency Pair:
            <div className="flex flex-column">
              <select
                name="sourceCurrency"
                className={`${inputClasses} w-0 block`}
                disabled={selectedInstrument !== undefined || disabled}
                onChange={(e) => {
                  const destination = destinationCurrency;
                  const currencyPair = `${e.target.value}:${isEmpty(destination) ? "*" : destination}`;
                  const pair =
                    currencyPair.replace(/\*/g, "")?.length <= 1
                      ? undefined
                      : currencyPair;
                  setCurrencyPair(pair);
                }}
                value={sourceCurrency}
              >
                <option value={"*"}>Any</option>
                {currencyPairs.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
              <select
                className={`${inputClasses} w-0 block`}
                name="destinationCurrency"
                disabled={selectedInstrument !== undefined || disabled}
                onChange={(e) => {
                  const source = sourceCurrency;
                  const currencyPair = `${isEmpty(source) ? "*" : source}:${e.target.value}`;
                  const pair =
                    currencyPair.replace(/\*/g, "")?.length <= 1
                      ? undefined
                      : currencyPair;
                  setCurrencyPair(pair);
                }}
                value={destinationCurrency}
              >
                <option value={"*"}>Any</option>
                {currencyPairs.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className={`flex flex-${layout} mr-1`}>
          <label className="my-auto">Fixed ($):</label>
          <input
            className={inputClasses}
            type="number"
            min={0}
            disabled={disabled}
            name="baseValue"
            value={fixedValue}
            onChange={(e) => {
              setFixedValue(e.target.value);
            }}
          />
        </div>
        <div className={`flex flex-${layout}`}>
          <label className="my-auto">Percentage (%):</label>
          <input
            type="number"
            className={inputClasses}
            value={percentage || ""}
            min={0}
            disabled={disabled}
            onChange={(e) => {
              setPercentage(e.target.value);
            }}
          />
        </div>
        <div className={`flex flex-${layout} mr-1`}>
          <label className="my-auto">Max Fee ($):</label>
          <input
            type="number"
            name="maxFee"
            value={maxFee || ""}
            disabled={disabled}
            min={0}
            className={inputClasses}
            onChange={(e) => {
              setMaxFee(e.target.value);
            }}
          />
        </div>
        {type === "override" && (
          <>
            <div className={`flex flex-${layout} mr-1`}>
              <label className="my-auto">Limit Type:</label>
              <select
                disabled={disabled}
                className={inputClasses}
                value={limitType}
                onChange={(e) => {
                  setLimitType(e.target.value as any);
                }}
              >
                <option value={undefined}>None</option>
                <option value="above">Above</option>
                <option value="below">Below</option>
              </select>
            </div>
            <div className={`flex flex-${layout} mr-1`}>
              <label className="my-auto">Limit Value:</label>
              <input
                type="number"
                className={inputClasses}
                min={0}
                value={limit || ""}
                disabled={limitType === undefined || disabled}
                required={limitType !== undefined}
                onChange={(e) => {
                  setLimit(e.target.value as any);
                }}
              />
            </div>
          </>
        )}
        <PermissionsProvider
          permission={AfriexPermissions.FEES_PAGE}
          action={AfriexActions.CLICK_FEES_EDIT_DELETE}
        >
          {type === "override" && (
            <div className="flex flex-row justify-between mr-3">
              <a
                className="block text-white mt-6 mx-1"
                onClick={() => onEdit?.(fee)}
              >
                <PencilIcon className="w-7 h-7 " color="grey" />
              </a>
              <a
                className="block text-white mt-6 mx-1"
                onClick={() => fee && onDelete?.(fee)}
              >
                <XIcon className="w-7 h-7" color="red" />
              </a>
            </div>
          )}
        </PermissionsProvider>
      </div>
    </div>
  );
};

export default FeeEntry;
