import { useState } from "react";
import { Button, MinusIcon, PlusIcon } from "../../../components";
import ImageModal from "../../../components/CustomModal/ImageModal";

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;
const KYCImage = ({ src, alt }: ImageProps) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const toggleFullScreen = () => setShowFullScreen(!showFullScreen);

  const button = (
    <Button onClick={toggleFullScreen} variant="unStyled" className="mt-2">
      <div
        className={`border-2 border-gray-700 w-5 h-5 flex justify-center rounded hover:bg-cyan-50 ${
          showFullScreen && "bg-cyan-50"
        }`}
      >
        {showFullScreen ? (
          <MinusIcon className="w-4 h-4 text-gray-700" />
        ) : (
          <PlusIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
        )}
      </div>
    </Button>
  );
  const width = showFullScreen ? "100%" : "200px";
  const imageView = (
    <>
      <img
        width={width}
        src={src ?? "https://via.placeholder.com/200"}
        alt={alt}
        onDoubleClick={toggleFullScreen}
      />
      {button}
    </>
  );

  return showFullScreen ? (
    <ImageModal
      isOpen={showFullScreen}
      onClose={toggleFullScreen}
      content={imageView}
    />
  ) : (
    imageView
  );
};

export default KYCImage;
