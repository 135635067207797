import axios from "../api/axios";
import {
  AfriexCountry,
  AfriexPaginatedResponse,
  SupportedCurrencies,
  TransactionTypes,
} from "../types";
import { AfriexQueryParams } from "../types/AfriexQueryParams";
import { TransactionProcessors } from "../types/ProcessorTypes";

const commonEndpoint = "/v2/admin/countries";

export type CountryResponse = AfriexPaginatedResponse<AfriexCountry[]>;

export type UpdateProcessorRequest = {
  currency: SupportedCurrencies;
  type: TransactionTypes;
  paymentMethodName: TransactionProcessors;
};

export const countryService = {
  getList: async (filters: AfriexQueryParams): Promise<CountryResponse> => {
    const paginationParams = {
      page: 0,
      limit: 20,
      columns: ["name", "iso2", "flag", "status", "currency"],
    } as Partial<AfriexQueryParams>;
    const params = { ...paginationParams, ...filters } as any;
    const url = `${commonEndpoint}?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },
  getItem: async (countryCode: string): Promise<AfriexCountry> => {
    const response = await axios.get(commonEndpoint + `/${countryCode}`);
    return response.data;
  },

  saveOne: async (
    itemId: string,
    body: Partial<AfriexCountry>
  ): Promise<AfriexCountry> => {
    const response = await axios.patch(commonEndpoint + `/${itemId}`, body);
    return response.data;
  },
};
