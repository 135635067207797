import React, { useState } from "react";
import { DotsHorizontalIcon } from "@heroicons/react/outline";
import {
  AfriexActions,
  AfriexAdmin,
  AfriexPermissions,
} from "../../../../types";
import { Button } from "../../../../components";
import { formatDate } from "../../../../utils/formatDate";
import DeactivateReasonModal from "../../../../components/CustomModal/ActivationModal";
import { PermissionsProvider } from "../../../../components/common/PermissionsProvider";
import useUserDetails from "../../../../hooks/useUserDetails";

const AdminTable = ({ items }: { items: AfriexAdmin[] }) => {
  return (
    <table
      className={`min-w-full divide-y divide-gray-200 border shadow-sm rounded-md`}
    >
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="pl-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Personnel Name
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Email
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Role
          </th>
          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
          >
            Last Login
          </th>

          <th
            scope="col"
            className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider invisible"
          >
            Options
          </th>
        </tr>
      </thead>

      <tbody className="bg-white divide-y divide-gray-200">
        {items.map((item) => {
          return <AdminTableRow item={item} key={item.userId} />;
        })}
      </tbody>
    </table>
  );
};

const AdminTableRow = ({ item }: { item: AfriexAdmin }) => {
  const textClassName = "text-sm text-gray-800";
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);

  const { userDetails: user } = useUserDetails(item.userId);

  return (
    <React.Fragment>
      <tr className="hover:bg-gray-100 transition-background transition-colors transition-opacity ease-in-out duration-500">
        <td
          className={`pl-6 py-4 whitespace-nowrap text-left ${textClassName} text-gray-800 font-medium`}
        >
          {item.fullName}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.email}
        </td>

        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {item.role}
        </td>

        {/* Last Login */}
        <td className={`py-4 whitespace-nowrap text-left ${textClassName}`}>
          {formatDate(item?.updatedAt ?? "")}
        </td>

        <td
          className={`py-4 whitespace-nowrap text-left relative ${textClassName}`}
        >
          <div
            onClick={() => setShowOptions(!showOptions)}
            className={`w-5 h-5 flex justify-center items-center text-gray-700 hover:border rounded-lg hover:border-gray-300 hover:bg-cyan-50 hover:text-cyan-500 cursor-pointer`}
          >
            <DotsHorizontalIcon className="text-gray-700 w-4 h-4 hover:text-gray-700" />
          </div>

          {showOptions && (
            <div className="flex flex-col items-end absolute right-14 bg-white border py-2 px-4 gap-2 z-10 rounded w-32">
              {!user.isDeactivated && (
                <PermissionsProvider
                  permission={AfriexPermissions.SETTINGS_PAGE}
                  action={AfriexActions.CLICK_DEACTIVATE_ADMIN}
                >
                  <Button
                    variant="unStyled"
                    className="hover:text-cyan-700"
                    onClick={() => setOpenDeactivateModal(!openDeactivateModal)}
                  >
                    Deactivate
                  </Button>
                </PermissionsProvider>
              )}
            </div>
          )}
        </td>

        <DeactivateReasonModal
          isOpen={openDeactivateModal}
          onClose={() => setOpenDeactivateModal(false)}
          user={user}
          type="admin"
        />
      </tr>
    </React.Fragment>
  );
};

export default AdminTable;
