import { ReactNode } from "react";
import CustomModal, { ModalProps } from ".";

interface ImageModalProps
  extends Pick<ModalProps, "isOpen" | "onClose" | "width" | "height"> {
  content: ReactNode;
}
const ImageModal: React.FC<React.PropsWithChildren<ImageModalProps>> = ({
  isOpen,
  onClose,
  content,
  width = "w-450",
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width={width}
      closeOnOutsideClick
      title=""
      hideX
    >
      {content}
    </CustomModal>
  );
};

export default ImageModal;
