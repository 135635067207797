import { SpinningLoader, Tabs } from "../../../components";
import CustomModal from "../../../components/CustomModal";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import { TabItem } from "../../../types";
import TierUserBulkUploadForm from "./TierUserBulkUploadForm";
import TierUserSingleUploadForm from "./TierUserSingleUploadForm";
import useTierUserUpdate from "./useTierUserUpdate";
import { isEmpty } from "lodash";

interface TierUserFormProps extends Pick<UserModalProps, "isOpen" | "onClose"> {
  userId?: string;
  tierId: string;
}

const TierForm = ({ isOpen, onClose, userId, tierId }: TierUserFormProps) => {
  const { isLoading, handleSubmit, handleBulkUpload, setUserEmail, userEmail } =
    useTierUserUpdate(onClose, tierId, userId);

  const updateLabel = isEmpty(userId)
    ? "Add User To Referral Tier +"
    : "Remove User From Referral Tier";
  const submitLabel = isEmpty(userId) ? "Submit" : "Remove";
  const isDisabled = isEmpty(userEmail) && isEmpty(userId);

  const tabs: TabItem[] = [
    {
      id: "single",
      title: "Single Upload",
      content: (
        <TierUserSingleUploadForm
          onClose={onClose}
          userEmail={userEmail}
          handleSubmit={handleSubmit}
          setUserEmail={setUserEmail}
          isDisabled={isDisabled}
          submitLabel={submitLabel}
        />
      ),
    },
    {
      id: "bulk",
      title: "Bulk Upload",
      content: (
        <TierUserBulkUploadForm
          onClose={onClose}
          userEmail={userEmail}
          handleSubmit={handleBulkUpload}
          setUserEmail={setUserEmail}
          isDisabled={isDisabled}
          submitLabel={submitLabel}
        />
      ),
    },
  ];

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      closeOnOutsideClick
      title={updateLabel}
    >
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader />
        </div>
      ) : (
        <Tabs
          key={isLoading?.toString()}
          tabs={tabs}
          queryKey={"tab"}
          containerSpacing="mt-0"
        />
      )}
    </CustomModal>
  );
};

export default TierForm;
