import { useState } from "react";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { Filter } from "../../../components/Filter";
import { CustomInput } from "../../../components/common/Input";
import { Button, SearchBar } from "../../../components";
import { UserFiltersProps } from "./types";
import { AfriexActions, AfriexPermissions } from "../../../types";

const UserFilters = ({
  filterCount,
  filtersToApply,
  clearFilters,
  selectAppliedFilters,
  handleApplyFilters,
  handleAutoComplete,
}: UserFiltersProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const handleShowFilterChange = (): void => {
    setShowFilters(!showFilters);
  };

  const handleApplyFilter = (): void => {
    handleApplyFilters();
    setShowFilters(false);
  };

  const handleClearFilters = (): void => {
    setShowFilters(false);
    clearFilters();
  };

  return (
    <PermissionsProvider
      permission={AfriexPermissions.USERS_PAGE}
      action={AfriexActions.CLICK_USER_SEARCH}
    >
      <>
        <div className="mt-5 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3 w-3/5">
              {/* Search Bar */}
              <SearchBar
                handleSearch={handleAutoComplete}
                handleAutoComplete={handleAutoComplete}
                clearFilters={clearFilters}
                inputClassName="w-4/6"
              />

              {/* Filter Box */}
              <div className="flex items-center space-x-3">
                <Filter
                  handleShowFilter={handleShowFilterChange}
                  label="Filters"
                  bodyClassName="left-1/2 -translate-x-1/2"
                >
                  {/* Filter Body */}
                  {showFilters && (
                    <div className="p-4 space-y-4 max-w-4xl mx-auto w-600">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex items-center gap-2">
                          <CustomInput
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              selectAppliedFilters(
                                "bankAccountNumber",
                                e.target.value
                              )
                            }
                            name="bankAccountNumber"
                            label="Bank Account No."
                            type="text"
                            value={filtersToApply.bankAccountNumber ?? ""}
                            className="w-full isFull"
                            placeholder="Enter Bank Account No."
                          />
                        </div>
                      </div>

                      <div className="flex justify-end">
                        <Button
                          onClick={handleClearFilters}
                          colorScheme="cyan"
                          variant="ghost"
                        >
                          Clear
                        </Button>

                        <Button
                          onClick={handleApplyFilter}
                          colorScheme="cyan"
                          className="ml-3"
                        >
                          Apply Filters {filterCount && ` (${filterCount})`}
                        </Button>
                      </div>
                    </div>
                  )}
                </Filter>
              </div>
            </div>

            {/* Right Side if needed */}
          </div>
        </div>
      </>
    </PermissionsProvider>
  );
};

export default UserFilters;
