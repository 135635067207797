import { useParams } from "react-router-dom";
import { Header, Tabs } from "../../../components";
import useRates from "../../../hooks/useRates";
import { AfriexRateInfo, TabItem } from "../../../types";
import TierSummary from "./Summary";
import TierRateTable from "./TierRateTable";
import TierTransactionList from "./TierTransactionList";
import TierUserList from "./TierUserList";
import useTierDetails from "./useTierDetails";

const TierDetails = () => {
  const params = useParams();
  const tierId = params?.tierId ?? "";
  const { tier, isLoading } = useTierDetails(tierId);
  const { rateInfo } = useRates();

  const userCountLabel = tier.userTotal
    ? ` (${tier.userTotal?.toLocaleString()})`
    : "";
  const transactionCountLabel = tier.transactionTotal
    ? ` (${tier.transactionTotal?.toLocaleString()})`
    : "";

  const tabs: TabItem[] = [
    {
      id: "details",
      title: "Details",
      content: (
        <TierSummary
          isDetail
          isLoading={isLoading}
          item={tier}
          rateInfo={rateInfo as AfriexRateInfo}
        />
      ),
    },
    {
      id: "rates",
      title: "Rates",
      content: (
        <TierRateTable
          isLoading={isLoading}
          rateInfo={rateInfo}
          discount={tier.discount}
        />
      ),
    },
    { id: "users", title: "Users" + userCountLabel, content: <TierUserList /> },
    {
      id: "transactions",
      title: "Transactions" + transactionCountLabel,
      content: <TierTransactionList />,
    },
  ];
  return (
    <div>
      <Header title={tier?.name ?? ""} />
      <Tabs key={isLoading?.toString()} tabs={tabs} queryKey={"tab"} />
    </div>
  );
};
export default TierDetails;
